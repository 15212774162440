import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getOne } from "../services/certitulos/CertitulosService";
import { useAuthState } from "./authContext";

export type ContextProps = {
    objectStudent: any;
    isLoadingStudent: boolean;
    isErrorStudent: boolean;
    statusResponseStudent: any;
    isSuccesStudent: boolean;
}

const StudentContext = React.createContext<ContextProps>({
    objectStudent: null,
    isLoadingStudent: false,
    isErrorStudent: false,
    statusResponseStudent: null,
    isSuccesStudent: false

});

export function StudentProvider(props: any) {

    const user = useAuthState();
    const email = user.email;

    const [statusResponse, setStatusResponse] = useState<any>();
    const [enableStudentFetch, setEnableStudentFetch] = useState(true);
    const [student, setStudent] = useState(null);

    const { isLoading, isError, isSuccess } = useQuery(["getOne", email], () => getOne(email), {
        enabled: enableStudentFetch,
        onError: (error: any) => setStatusResponse(error.response.status),
        onSuccess: (data: any) => {
            setStatusResponse(data.status);
            setStudent(data.data)
            setEnableStudentFetch(false);
        }
    });

    const value = useMemo(() => {
        return ({
            objectStudent: student,
            isLoadingStudent: isLoading,
            isErrorStudent: isError,
            statusResponseStudent: statusResponse,
            isSuccesStudent: isSuccess
        })
    }, [student, isLoading, isError, statusResponse])

    return <StudentContext.Provider value={value} {...props} />
}

export function useStudent() {
    const context = React.useContext(StudentContext);

    if (!context) {
        throw new Error('useStudent debe estar dentro del proveedor StudentContext')
    }

    return context;
}