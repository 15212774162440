import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.87b3a1';
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthProvider } from './context/authContext';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  <AuthProvider>
    <QueryClientProvider client={queryClient}>

      <App />

    </QueryClientProvider>
  </AuthProvider>
);