import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthDispatch} from "../../context/authContext";

export default function MasterLogin() {
    const [searchParams] = useSearchParams();
    const authDispatch = useAuthDispatch();
    const navigate=useNavigate()

   
    useEffect(()=>{
        const FetchUserLogin = async () => {        
            try {
                authDispatch({
                    type: 'login',
                    token: searchParams.get("id"),
                });
            } catch (error) {
            }
        }
        FetchUserLogin();
        navigate("/");
    },[]);    
}