import { lazy } from "react";

//import icons
import { useAuthState } from "../context/authContext";
//Import con Lazy las vistas que se van a utilizar
//Gestión de Talento
const HomeTE = lazy(() => import("../views/Student"));
const TeacherView = lazy(() => import("../views/Teachers"));
const Evaluation = lazy(() => import("../views/Evaluation"));
const Page404 = lazy(() => import("../pages/PageNotFound"));

const teacherEvaluation = {
    title: "Evaluación Docente",
    screens: [
        {
            title: "Evaluación docente",
            url: "home",
            component: HomeTE
        },
        {
            url: "teachers-evaluate",
            component: TeacherView
        },
        {
            url: "evaluation",
            component: Evaluation
        },
        {
            url: "*",
            component: Page404
        }
    ]
};

// Se usaría esta función desde dashboard para acceder a las vistas de cada Admin
// Cuando se agrueguen más Admin, usamos un switch para que acceda a al rol correcto.


function GetRouter() {
    const User = useAuthState();

    var marcaUrl = "";
    let auxUrl = window.location.origin.split(".")[1];

    switch (auxUrl) {
        case "educem":
            marcaUrl = "educem.mx"
            break;

        case "tecmas":
            marcaUrl = "tecmas.mx"
            break;

        case "institutofirenze":
            marcaUrl = "institutofirenze.edu.mx";
            break;
    
        default:
            break;
    }

    const returnUser = () => { window.location.href = `https://sam.${marcaUrl  ? marcaUrl : "educem.mx"}/`; }

    if(User.isAuthenticated === true)
    return (teacherEvaluation);
    else{
        returnUser();
    }
}

export default GetRouter;