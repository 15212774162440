import axios from "axios";
import {GET_STUDENT_INFO_BY_EMAIL_ENDPOINT, GET_ALL_EVALUATION, GET_TEACHERS_BY_GROUP, 
    POST_SAVE_EVALUATION, POST_SAVE_COMMENT, GET_ALERT_WORDS, POST_EVALUATE_TEACHER, POST_SAVE_PDF, GET_EVALUATION_BY_ID } from "../../utils/endpoints/certitulos/CertitulosEndpoints";

export const getOne = (email:any) => {
    return axios.get(GET_STUDENT_INFO_BY_EMAIL_ENDPOINT(email));
}

export const getEvaluationById = (id:any, alumnoid: string) => {
    return axios.get(GET_EVALUATION_BY_ID(id, alumnoid));
}

export const getTeachersEvaluated = (id:any, idAlumno: any, idEvaluacion: any) => {
    return axios.get(GET_TEACHERS_BY_GROUP(id, idAlumno, idEvaluacion));
}

export const getAllEvaluations = (group: any) => {
    return axios.get(GET_ALL_EVALUATION(group));
}

export const requestSaveEvaluation = (evaluation:any) =>{
    return axios.post(POST_SAVE_EVALUATION,evaluation);
}

export const requestSaveComment = (comment:any) =>{
    return axios.post(POST_SAVE_COMMENT,comment);
}

export const getAlertWords = () => {
    return axios.get(GET_ALERT_WORDS());
}

export const postEvaluateTeacher = (docente: any) => {
    return axios.post(POST_EVALUATE_TEACHER, docente)
}

export const postPdfEvaluation = (contentToGenerate: FormData) => {
    return axios.post(POST_SAVE_PDF, contentToGenerate, {
        headers: {
            'Content-Type': 'multi-part/form-data'
        },
        responseType: 'blob'
    })
}
