import React, { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import Page404 from './pages/PageNotFound';
import Public from './pages/Public';
import { LinearProgress } from '@mui/material';
import { useAuthState } from './context/authContext';
import { GET_USER_LOGIN_ENDPOINT } from './utils/endpoints/ap/ApEndpoints';
import { GET_STUDENT_INFO_BY_EMAIL_ENDPOINT } from './utils/endpoints/certitulos/CertitulosEndpoints';
import { SC_KEY, TOKEN_KEY } from './utils/constants';
import MasterLogin from './pages/Login/MasterLogin';
import RedirectToApp from './utils/RedirectToApp';
import reportWebVitals from './reportWebVitals';
import { StudentProvider } from './context/StudentContext';
import ErrorGeneral from './components/errors/ErrorGeneral';
import { Organizations, Proveedor } from './components/configuration/config';
import { EvaluationProvider } from './context/EvaluationContext';

const removeToken = () => {
    localStorage.removeItem(TOKEN_KEY);
}


const RenderAppFetchData = () => {

    const [dataAP, getDataAP] = useState();
    const [dataMS, getDataMS] = useState();
    const [isError, setIsError] = useState(false);
    const user = useAuthState();
    var CryptoJS = require("crypto-js");
    var access = false;
    var marcaUrl = "";
    let auxUrl = window.location.origin.split(".")[1];

    switch (auxUrl) {
        case "educem":
            marcaUrl = "educem.mx"
            break;

        case "tecmas":
            marcaUrl = "tecmas.mx"
            break;

        case "institutofirenze":
            marcaUrl = "institutofirenze.edu.mx";
            break;

        default:
            break;
    }

    const domain = user.email.split("@")[1]

    const favicon = document.getElementById('favicon');
    favicon.href = Proveedor['Aws'] + Organizations[domain.includes("educem") ? "Educem" : domain.includes("tecmas") ? "Tecmas" : "FirenzeS"].Img.Logo.favicon;


    const returnUser = () => { window.location.href = `https://sam.${marcaUrl ? marcaUrl : "educem.mx"}`; }

    useEffect(() => {
        if (!dataAP) {
            fetch(GET_USER_LOGIN_ENDPOINT, { headers: new Headers({ 'Authorization': `Bearer ${user.token}` }) }).then((res) => res.json()).then((data) => getDataAP(data)).catch(err => setIsError(true));
        }
        if (!dataMS) {
            fetch(GET_STUDENT_INFO_BY_EMAIL_ENDPOINT(user.email), { headers: new Headers({ 'Authorization': `Bearer ${user.token}` }) }).then((res) => res.json()).then((data) => { getDataMS(data); }).catch(err => setIsError(true));
        }

    }, [dataAP, dataMS]);

    if (isError) {

    } else {
        if (!dataAP || !dataMS) {
        } else {
            dataAP.scopes.map((scope) => {
                if (scope.scopeid === 3 || scope.scopeid === 18 || scope.scopeid === 20 || scope.scopeid === 21 || scope.scopeid === 22) {
                    access = true;
                }
            });


            if (access) {
                if (dataMS.status !== 500) {
                    if (localStorage.getItem('React.LC.KY.AP') === null) {
                        var dataAp_encrypt = CryptoJS.AES.encrypt(JSON.stringify(dataAP), SC_KEY).toString();
                        localStorage.setItem('React.LC.KY.AP', dataAp_encrypt)
                    }

                    if (localStorage.getItem('React.LC.KY.MS') === null) {
                        var dataMS_encrypt = CryptoJS.AES.encrypt(JSON.stringify(dataMS), SC_KEY).toString();
                        localStorage.setItem('React.LC.KY.MS', dataMS_encrypt);
                    }
                } else {
                    removeToken();
                    returnUser();
                }

            } else {
                removeToken();
                returnUser();
            }
        }
    }

    return (
        <>
            {(isError) ?
                <ErrorGeneral></ErrorGeneral> : (!dataAP || !dataMS) ?
                    <LinearProgress></LinearProgress>
                    :
                    <StudentProvider>
                        <EvaluationProvider>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/masterlogin/" element={<MasterLogin />}></Route>
                                    <Route path="/getAppCon/:id" element={<RedirectToApp />}></Route>
                                    {/* <Route path="home" element={<Dashboard />}></Route> */}
                                    <Route path="/*" element={<Page404 />}> </Route>
                                    <Route path="/v1/evaluations/*" element={<Dashboard />}></Route>
                                    <Route path="/" element={<Navigate path="/" to="/v1/evaluations/home" />}> </Route>
                                    <Route path="/public" element={<Public />}> </Route>
                                </Routes>
                            </BrowserRouter>
                        </EvaluationProvider>
                    </StudentProvider>


            }
        </>

    )
}

export default RenderAppFetchData;