import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAuthState } from '../../context/authContext';

const useStyles = makeStyles({
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});


export default function ErrorGeneral() {
    const user = useAuthState();
    const domain = user.email.split("@")[1]
    const classes = useStyles();
    return (
        <Box className={classes.center}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 100
                }}
            >
                <Typography sx={{ textAlign: 'center', marginBottom: 1, fontWeight: 'bold' }} variant='h3'>BUSCAMOS POR TODAS PARTES</Typography>
                <Typography sx={{ textAlign: 'center', color: '#898989' }} variant='h6'>No hemos encontrado tu información</Typography>
                <Typography sx={{ textAlign: 'center', color: '#898989', marginBottom: 2 }} variant='h6'>Contacta a tu coordinador.</Typography>
                <Box className={classes.center} sx={{ marginBottom: 2 }}>
                    <a href={`https://sam.${domain}/`} style={{
                        font: '16px Arial',
                        padding: '9px 10px 9px 10px',
                        backgroundColor: '#293881',
                        color: '#ffffff',
                        borderRadius: 3,
                        textDecoration: 'none'
                    }}>REGRESAR A SAM!</a>
                </Box>
                <Box className={classes.center}>
                    <img alt='loading' src={require('../../static/2696469.jpg')} style={{ height: 280 }} />
                </Box>
            </Box>
        </Box>
    )
}