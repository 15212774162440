import { Link } from "react-router-dom";
import React from 'react'
import { useAuthState } from '../../context/authContext';
import { Box, Button, Container, Typography } from '@material-ui/core';

function Page404() {
   const user = useAuthState();

   return (
      <Box
         sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh'
         }}
      >
         <Container maxWidth="md">
            {
               (user.isAuthenticated === true) ?
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                     }}
                  >
                     <Box
                        sx={{
                           position: 'absolute',
                           top: 50,
                        }}
                     >
                        <img alt='loading' src={require('../../static/404-GIF2.gif')} style={{ height: 250 }} />
                        <Typography variant="h5" sx={{ fontStyle: 'italic', mt: 2, mb: 2 }}>
                           whoops, no hay nada que ver aqui...
                        </Typography>
                        <Button sx={{ display: 'flex', margin: 'auto' }} variant="contained" olor="secondary" component={Link} to={'/v1/evaluations/home'}>Volver al inicio</Button>
                     </Box>
                  </Box>
                  :
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                     }}
                  >
                     <Box
                        sx={{
                           position: 'absolute',
                           top: 50,
                        }}
                     >
                        <img alt='loading' src={require('../../static/timeout.gif')} style={{ height: 250 }} />
                        <Typography variant="h5" sx={{ fontStyle: 'italic', mt: 2, mb: 2, }}>
                           Whoops, parece que ha expirado tu sesión...
                        </Typography>
                        <a href='https://sam.educem.mx/' style={{
                           font: '16px Arial',
                           padding: '9px 10px 9px 150px',
                           backgroundColor: '#9c27b0',
                           color: '#ffffff',
                           borderRadius: 3,
                           textDecoration: 'none',
                           display: 'flex',
                           margin: 'auto',
                        }}>REGRESAR A SAM!</a>
                     </Box>
                  </Box>
            }

         </Container>
      </Box>
   )
}

export default Page404;