import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import React from 'react'
import './App.css';
import MasterLogin from './pages/Login/MasterLogin';
import { useAuthState } from './context/authContext';
import { useState } from 'react';
import RenderAppFetchData from './RenderAppFetchData';
import DialogExpirationSession from './components/general/DialogExpirationSession';
import Public from './pages/Public';
import Page404 from './pages/PageNotFound';
import Dashboard from './pages/dashboard';

function App() {

  const usr = useAuthState();

  const [open, setOpen] = useState(false);

  window.addEventListener('click', () => {
    const currentTime = Date.now() / 1000;
    let exp = document.cookie.split("; ").find((row) => row.startsWith("exp="))?.split("=")[1];

    if (exp === undefined) {
      setOpen(true);
    } else if (exp < currentTime) {
      setOpen(true);
    }
  });
  return (
    <>
      <DialogExpirationSession open={open}></DialogExpirationSession>
      {(!usr.token || !usr.isAuthenticated) ?
        <BrowserRouter>

          <Routes>
            <Route path="/masterlogin/" element={<MasterLogin />}></Route>
            {/* <Route path="/home" element={<Dashboard />}></Route> */}
            <Route path="/*" element={<Page404 />}> </Route>
            <Route path="/v1/evaluations/*" element={<Dashboard />}></Route>
            <Route path="/" element={<Navigate path="/" to="/v1/evaluations/home" />}> </Route>
            <Route path="/public" element={<Public />}> </Route>
          </Routes>

        </BrowserRouter>
        :
        <RenderAppFetchData></RenderAppFetchData>
      }
    </>


  );
}

export default App;

