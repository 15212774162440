// Producción
export const API_URL = 'https://apievaluacion.educem.mx/api/private'; // CERTITULOS ALUMNO

//TEST
//export const API_URL = 'https://apievaluaciontest.mecap.mx/api/private'; // CERTITULOS ALUMNO

//LOCAL
//export const API_URL = 'http://192.168.3.209:9034/api/private'; // CERTITULOS ALUMNO
export const API_AP = 'https://educemap.educem.mx/user/me';
export const SC_KEY = '979E7FAEBE46C439'
export const TOKEN_KEY = "token";
