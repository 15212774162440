import { FC, forwardRef } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

interface DialogExpirationSessionProps {
    open: boolean
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

var url = window.location.hostname;

if (url.includes("educem")) {
    url = "https://sam.educem.mx/";
} else if (url.includes("firenze")) {
    url = "https://sam.institutofirenze.edu.mx/";
} else if (url.includes("tecmas")) {
    url = "https://sam.tecmas.mx/";
}else{
    url = "http://localhost:3000/";
}

const DialogExpirationSession: FC<DialogExpirationSessionProps> = ({ open }) => {

    const handleLogout = () => {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
        window.location.href = url;
    }

    return (
        <Dialog
            sx={{ backgroundColor: 'rgba(41,56,129,.7)' }}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
        >
            <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
                <Typography variant='h5'>Sesión expirada</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Presiona cerrar sesión e inicia de nuevo
                </DialogContentText>
                <DialogActions>
                    <Button fullWidth onClick={handleLogout} variant='contained'>Cerrar Sesión</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default DialogExpirationSession;