// EvaluationProvider.js

import React, { useMemo, useState } from 'react';

export type ContextProps = {
  objectEvaluation: any;
  setObjectEvaluation: React.Dispatch<React.SetStateAction<any>>;
};

const EvaluationContext = React.createContext<ContextProps | undefined>(undefined);

export function EvaluationProvider({ children, initialEvaluation }: any) {
  const [objectEvaluation, setObjectEvaluation] = useState(initialEvaluation);

  const value = useMemo(() => {
    return {
      objectEvaluation,
      setObjectEvaluation,
    };
  }, [objectEvaluation]);

  return <EvaluationContext.Provider value={value}>{children}</EvaluationContext.Provider>;
}

export function useEvaluation() {
  const context = React.useContext(EvaluationContext);

  if (!context) {
    throw new Error('useEvaluation debe estar dentro del proveedor EvaluationContext');
  }

  return context;
}
