import { Typography, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from "@mui/system";
import { AppSam } from '../types';
import { FC } from "react";
import { SC_KEY, TOKEN_KEY } from '../utils/constants'

interface AppSamProps {
    scope: AppSam
}

const useStyles = makeStyles({
    root: {
        whiteSpace: 'nowrap',
        "&:hover": {
            whiteSpace: 'normal'
        }
    },
    paperApp: {
        borderColor: 'transparent',
        borderRadius: 7,
        paddingLeft: '2px',
        paddingRight: '2px',
        height: 95,
        width: 95,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '7px',
        bgcolor: 'transparent',
        lineHeight: '.5 !important'
    }
});

const AppSAM: FC<AppSamProps> = ({ scope }) => {

    const currentURL = window.location.origin;

    const classes = useStyles();
    var CryptoJS = require("crypto-js");
    const token = localStorage.getItem(TOKEN_KEY);
    const _token = token ? token : 'notoken';
    const arr = _token.split('.');

    return (
        <Grid key={scope.scopeid} item xs={4} sm={4} md={4} lg={4}  >
            {
                (scope.isapp) ?
                    <a rel="noreferrer" target='_blank' href={`${currentURL}/getAppCon/${CryptoJS.AES.encrypt(`${scope.scopeid}|${arr[0]}`, SC_KEY).toString().replace(/\//g, 'Por21Ld')}`} style={{ textDecoration: 'none' }}>
                        <Paper variant="outlined" className={classes.paperApp} sx={{ "&:hover": { bgcolor: 'rgba(22,41,180,0.1)' } }}>
                            <div className={classes.root}>
                                <Box component="div"
                                    sx={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        textAlign: 'center',
                                        "&:hover": {
                                            textOverflow: 'inherit',
                                            textAlign: 'center'
                                        }
                                    }}>
                                    <Box display="flex" flexDirection="column" alignItems="center" sx={{ marginBottom: 1 }}>
                                        {
                                            (!scope.iconpath) ?
                                                <span className="material-icons" style={{ fontSize: 38, color: scope.scopecolor }} >{scope.scopeicon}</span>
                                                :
                                                <img alt={`${scope.scopename}`} src={scope.iconpath} style={{ width: 38, height: 38 }}></img>
                                        }
                                    </Box>
                                    <Typography variant="caption" sx={{ lineHeight: '1.3 !important' }}>
                                        {`${scope.scopename}`}
                                    </Typography>
                                </Box>
                            </div>
                        </Paper>
                    </a>
                    :
                    <a target="_blank" rel="noreferrer" href={`${scope.scopepath}`} style={{ textDecoration: 'none' }}>
                        <Paper key={scope.scopeid} variant="outlined" className={classes.paperApp} sx={{ "&:hover": { bgcolor: 'rgba(22,41,180,0.1)' } }}>
                            <div className={classes.root}>
                                <Box component="div"
                                    sx={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        textAlign: 'center',
                                        "&:hover": {
                                            textOverflow: 'inherit',
                                            textAlign: 'center'
                                        }
                                    }}>
                                    <Box display="flex" flexDirection="column" alignItems="center" sx={{ marginBottom: 1 }}>
                                        {
                                            (!scope.iconpath) ?
                                                <span className="material-icons" style={{ fontSize: 38, color: scope.scopecolor }} >{scope.scopeicon}</span>
                                                :
                                                <img alt={`${scope.scopename}`} src={scope.iconpath} style={{ width: 38, height: 38 }}></img>
                                        }
                                    </Box>
                                    <Typography variant="caption" sx={{ lineHeight: '1.3 !important' }}>
                                        {`${scope.scopename}`}
                                    </Typography>
                                </Box>
                            </div>
                        </Paper>
                    </a>
            }
        </Grid>
    )
}

export default AppSAM;