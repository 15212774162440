import { Link, Typography } from "@mui/material";
import { useAuthState } from "../../../context/authContext";

//Copyrigth
const Copyright = () => {

  const user = useAuthState();
  const domain = user.email.split("@")[1]
  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={{ marginTop: 3 }}>
      {'Copyright © '}
      <Link color="inherit" href="/" sx={{ textDecoration: 'none' }}>
        {domain.includes("educem") ? "EDUCEM" : domain.includes("tecmas") ? "TECMAS" : "FIRENZE"} | EVALUACIÓN DOCENTE
      </Link>{' '}
      {new Date().getFullYear()}
      {'. v2024.03.15' + Math.floor(2305 * (100 - 1000) + 1000) + '-1'}
    </Typography>
  );
}

export default Copyright;