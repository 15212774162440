import { useParams } from 'react-router-dom';
import { SC_KEY, TOKEN_KEY } from './constants';
import { useAuthState } from '../context/authContext';

export default function RedirectToApp() {
    const { id } = useParams();
    var CryptoJS = require("crypto-js");
    const User = useAuthState();

    var bytes = CryptoJS.AES.decrypt(id.replace(/Por21Ld/g, '/'), SC_KEY);
    var idDecrypt = bytes.toString(CryptoJS.enc.Utf8);
    const arr = idDecrypt.split('|');

    var bytesAP = CryptoJS.AES.decrypt(localStorage.getItem('React.LC.KY.AP'), SC_KEY);
    var userAP = JSON.parse(bytesAP.toString(CryptoJS.enc.Utf8));

    userAP.scopes.forEach((scope) => {
        if (scope.scopeid === parseInt(arr[0])) {
            window.location.href = `${scope.scopepath}?id=${User.token}`;
        }
    });
}